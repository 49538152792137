import { jwtHelper } from './jwt-helper'
import { authService } from '../services'
import { store } from '../store'

export function authHeader () {
  let user = jwtHelper.getUser()

  if (user && user.token) {
    if (jwtHelper.isStoredValid()) {
      const currentProject = store?.state?.projectSubmission?.selectedProjectId ?? null
      // console.log('cur port', currentProject)
      return { Authorization: 'Bearer ' + user.token, project: currentProject }
    } else {
      if (jwtHelper.canRefresh()) {
        user = authService.refresh()
        if (user && user.token) {
          const currentProject = store?.state?.projectSubmission?.selectedProjectId ?? null
          // console.log('cur port', currentProject)
          return { Authorization: 'Bearer ' + user.token, project: currentProject }
        } else {
          return {}
        }
      } else {
        return {}
      }
    }
  } else {
    return {}
  }
}
