import { apiRoute, authHeader, handleResponse } from '../helpers'

export const staffSubmissionService = {
  get,
  getByIdentifiers,
  getAll,
  getCount,
  getCovid,
  getPneumonia,
  getTuberculosis,
  getGeneral,
  getGeneralForPatient,
  getForPatient,
  update,
  feedback
}

function get (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const _model = localStorage.getItem('ai_model')
  let _modelPath = 'tb'
  if (_model === 'Pneumonia') {
    _modelPath = 'pneumonia'
  } else if (_model === 'Tuberculosis') {
    _modelPath = 'tb'
  } else if (_model === 'General') {
    _modelPath = 'general'
  }

  return fetch(apiRoute() + '/api/v1/staff/' + _modelPath + '/' + id, requestOptions).then(handleResponse)
}

function getForPatient (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const _model = localStorage.getItem('ai_model')
  let _modelPath = 'tb'
  if (_model === 'Pneumonia') {
    _modelPath = 'pneumonia'
  } else if (_model === 'Tuberculosis') {
    _modelPath = 'tb'
  } else if (_model === 'General') {
    _modelPath = 'general'
  }

  return fetch(apiRoute() + '/api/v1/staff/' + _modelPath + '/list/patient/' + id, requestOptions).then(handleResponse)
}

function getByIdentifiers (params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/submissions/' + params.patient + '/' + (params.study ? params.study : null), requestOptions).then(handleResponse)
}

function getAll (filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/submissions/' + filter, requestOptions).then(handleResponse)
}

function getCount (project) {
  const header = authHeader()
  header.project = project
  const requestOptions = {
    method: 'GET',
    headers: header
  }

  return fetch(apiRoute() + '/api/v1/staff/tb/count', requestOptions).then(handleResponse)
}

function getCovid (period) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/covid/list/' + period, requestOptions).then(handleResponse)
}

function getPneumonia (period, page, project) {
  const header = authHeader()
  header.project = project
  const requestOptions = {
    method: 'GET',
    headers: header
  }

  return fetch(apiRoute() + '/api/v1/staff/pneumonia/list/' + period + '/' + page, requestOptions).then(handleResponse)
}

function getTuberculosis (period, page, project) {
  const header = authHeader()
  header.project = project
  const requestOptions = {
    method: 'GET',
    headers: header
  }

  return fetch(apiRoute() + '/api/v1/staff/tb/list/' + period + '/' + page, requestOptions).then(handleResponse)
}

function getGeneral (period, page, project) {
  const header = authHeader()
  header.project = project
  const requestOptions = {
    method: 'GET',
    headers: header
  }

  return fetch(apiRoute() + '/api/v1/staff/general/list/' + period + '/' + page, requestOptions).then(handleResponse)
}

function getGeneralForPatient (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/general/list/patient/' + id, requestOptions).then(handleResponse)
}

function update (id, labels, tags) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      labels: labels,
      tags: tags
    })
  }

  return fetch(apiRoute() + '/api/v1/submissions/callback/update/' + id, requestOptions).then(handleResponse)
}

function feedback (id, pathologies, other, diagnosis) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      pathologies: pathologies,
      other: other,
      diagnosis: diagnosis
    })
  }

  return fetch(apiRoute() + '/api/v1/submissions/feedback/update/' + id, requestOptions).then(handleResponse)
}
